<template>
    <div v-if="!selectedProperty">
        <v-select
            label="Property"
            :items="fields"
            v-model="selectedProperty"
            outlined
            dense
        />
    </div>
    <div v-else>
        <v-row class="text-left">
            <v-col cols=12><b>{{ selectedProperty }}</b></v-col>
            <v-col cols=3>
                <v-select
                    label="Condition"
                    :items="operators"
                    v-model="selectedOperator"
                    outlined
                    dense
                />
            </v-col>
            <v-col>
                <v-text-field
                    label="Value"
                    v-model="selectedValue"
                    outlined
                    dense
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    const operators = ['==', '>', '>=', '<', '<=', 'CONTAINS'];

    export default {
        name: 'ProductAnalyticsFilterProperties',
        props: {
            eventsProperties: Array,
            isProperty: {
                type: Boolean,
                default: false,
            },

        },
        data() {
            return {
                operators,
                selectedProperty: undefined,
                selectedOperator: undefined,
                selectedValue: undefined,
            };
        },
        computed: {
            fields() {
                return this.eventsProperties;
            },
            filter() {
                const field = `properties.${this.selectedProperty}`;
                const filter = {
                    field,
                    operator: this.selectedOperator,
                    value: this.selectedValue,
                };
                return filter;
            },
        },
        watch: {
            filter(value) {
                this.$emit('input', value);
            },
        },
        methods: {
            clear() {
                this.selectedProperty = undefined;
                this.selectedOperator = undefined;
                this.selectedValue = undefined;
            },
        },
    };
</script>
