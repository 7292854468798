import webapi from '@/core/webapi';
import CONSTANTS from '@/constants';

const BASE_URI = `${CONSTANTS.API_BASE}/gateway/product-analytics`;

export default class AnalyticsAPI {
    static async listReports() : Promise<any> {
        const uri = `${BASE_URI}/list-reports`;
        return webapi.get({ uri });
    }

    static async getMeta(reportKey: string) : Promise<any> {
        const uri = `${BASE_URI}/report/${reportKey}/get-metadata`;
        return webapi.get({ uri });
    }

    static async queryEvents(
        reportKey: string, filters: Array<Record<string, any>>,
    ) : Promise<any> {
        const uri = `${BASE_URI}/report/${reportKey}/query`;
        const body = { filters };
        return webapi.post({ uri, body });
    }
}
