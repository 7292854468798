<template>
    <v-card outlined>
        <v-card-text>
            <v-select :items="eventsList" v-model="selectedEvent" outlined dense label="Event" />
            <v-btn v-if="selectedEvent" icon small @click="addPropertyFilter"><v-icon small>$filter</v-icon></v-btn>
            <v-btn icon small @click="$emit('on-delete')"><v-icon small>$delete</v-icon></v-btn>
        </v-card-text>
        <div v-for="(pf, i) in propertyFilters" :key="i">
            <v-divider />
            <v-card-text>
                <filter-properties :events-properties="selectedEventProperties" v-if="selectedEvent" v-model="propertyFilters[i]" />
            </v-card-text>
        </div>
    </v-card>
</template>

<script>
    import FilterProperties from './filter-properties.vue';

    const operators = ['==', '>', '>=', '<', '<=', 'CONTAINS'];

    const deleteFromArray = (arr, idx) => {
        arr.splice(idx, 1);
    };

    export default {
        name: 'ProductAnalyticsFilterEvent',
        components: {
            FilterProperties,
        },
        props: {
            eventsList: Array,
            eventsProperties: Object,
        },
        data() {
            return {
                operators,
                selectedEvent: undefined,
                propertyFilters: [],
            };
        },
        computed: {
            selectedEventProperties() {
                if (this.selectedEvent === undefined) return undefined;
                const properties = this.eventsProperties[this.selectedEvent];
                const items = [];

                Object.keys(properties).forEach((key) => {
                    const prop = properties[key];
                    items.push(prop.key);
                });
                return items;
            },
            filters() {
                const filters = [];

                if (this.selectedEvent !== undefined) {
                    filters.push({ field: 'event', operator: '==', value: this.selectedEvent });
                }

                this.propertyFilters.forEach((fitem) => {
                    filters.push(fitem);
                });
                return filters;
            },
        },
        watch: {
            filters() {
                this.$emit('input', this.filters);
            },
        },
        methods: {
            clear() {
                this.selectedEvent = undefined;
                this.selectedProperty = undefined;
                this.selectedOperator = undefined;
            },
            addPropertyFilter() {
                this.propertyFilters.push({});
            },
            deletePropertyFilter(idx) {
                deleteFromArray(this.propertyFilters, idx);
            },
        },
    };
</script>
