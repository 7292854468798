<template>
    <v-card outlined>
        <v-simple-table
            fixed-header
            height="800px"
        >
            <thead>
                <tr>
                    <th>Event</th>
                    <th>Timestamp</th>
                    <th>User</th>
                </tr>
            </thead>
            <tbody class="text-left">
                <tr
                    v-for="event in events"
                    :key="event.timestamp"
                    @click="selectEvent(event)"
                    style="cursor: pointer"
                >
                    <td>{{ event.event }}</td>
                    <td>{{ event.timestamp }}</td>
                    <td>{{ event.user_id }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>

<script>
    export default {
        name: 'ProductAnalyticsViewerList',

        props: {
            events: Array,
        },
        methods: {
            selectEvent(event) {
                this.$emit('on-select', event);
            },
        },
    };
</script>
