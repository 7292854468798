<template>
    <request :request="requestMeta" @on-success="setMeta">
        <request :request="requestEvents" @on-success="setEvents">
            <v-row>
                <v-col cols=2>
                    <filters
                        :events-list="meta.events_list"
                        :events-properties="meta.event_properties"
                        v-model="selectedFilters"
                    />
                    <v-btn @click="queryEvents">Confirm</v-btn>
                </v-col>
                <v-col cols=10>
                    <viewer :events="events" />
                </v-col>
            </v-row>
        </request>
    </request>
</template>

<script>
    import AnalyticsAPI from '../webapi';
    import Filters from './filters/filters.vue';
    import Viewer from './viewer/viewer.vue';

    export default {
        name: 'ProductAnalyticsSingleView',
        components: {
            Filters,
            Viewer,
        },
        props: {
            reportKey: String,
        },
        data() {
            return {
                requestEvents: undefined,
                requestMeta: () => AnalyticsAPI.getMeta(this.reportKey),
                events: [],
                selectedFilters: [],
                meta: {},
            };
        },
        watch: {
            reportKey: {
                immediate: true,
                handler() {
                    this.queryEvents();
                },
            },
        },
        methods: {
            queryEvents() {
                console.warn('Query Events');
                this.requestEvents = () => AnalyticsAPI.queryEvents(this.reportKey, this.selectedFilters);
            },
            setMeta(data) {
                this.meta = data;
            },
            setEvents(data) {
                console.warn('set events after quer', data);
                this.events = data;
            },
        },
    };
</script>
