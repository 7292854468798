<template>
    <div>
        <filter-event
            v-for="(filter, i) in filters"
            :events-list="eventsList"
            :events-properties="eventsProperties"
            v-model="filters[i]"
            :key="i"
            @on-delete="deleteFilter"
        />
        <v-btn icon @click="addFilter"><v-icon>$add</v-icon></v-btn>
    </div>
</template>

<script>
    import FilterEvent from './filter-event.vue';

    const operators = ['==', '>', '>=', '<', '<=', 'CONTAINS'];

    export default {
        name: 'ProductAnalyticsFilters',
        components: {
            FilterEvent,
        },
        props: {
            eventsList: Array,
            eventsProperties: Object,
        },
        data() {
            return {
                operators,
                selectedEvent: undefined,
                selectedProperty: undefined,
                selectedOperator: undefined,
                selectedValue: undefined,
                filters: [],
            };
        },
        computed: {
            selectedEventProperties() {
                if (this.selectedEvent === undefined) return undefined;
                const properties = this.eventsProperties[this.selectedEvent];
                const items = [];

                Object.keys(properties).forEach((key) => {
                    const prop = properties[key];
                    items.push(prop.key);
                });
                return items;
            },
        },
        watch: {
            filters() {
                this.$emit('input', this.filters);
            },
        },
        methods: {
            clear() {
                this.selectedEvent = undefined;
                this.selectedProperty = undefined;
                this.selectedOperator = undefined;
            },
            addFilter() {
                this.filters.push([]);
            },
            deleteFilter(idx) {
                this.filters.splice(idx, 1);
            },
        },
    };
</script>
