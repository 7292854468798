<template>
    <product-analytics-single-view :report-key="reportKey" />
</template>

<script>
    import ProductAnalyticsSingleView from '@/modules/product-analytics/components/product-analytics-single-view.vue';

    export default {
        name: 'ProductAnalyticsReportPage',
        components: {
            ProductAnalyticsSingleView,
        },
        props: {
            reportKey: String,
        },
    };
</script>
