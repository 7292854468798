<template>
    <v-row>
        <v-col>
            <product-analytics-viewer-list :events="events" @on-select="selectEvent" />
        </v-col>
        <v-col>
            <product-analytics-viewer-details :event="selectedEvent" v-if="selectedEvent" />
        </v-col>
    </v-row>
</template>

<script>
    import ProductAnalyticsViewerList from './viewer-list.vue';
    import ProductAnalyticsViewerDetails from './viewer-details.vue';

    export default {
        name: 'ProductAnalyticsViewer',
        components: {
            ProductAnalyticsViewerList,
            ProductAnalyticsViewerDetails,
        },
        props: {
            events: Array,
        },
        data() {
            return {
                selectedEvent: undefined,
            };
        },
        watch: {
            events() {
                this.selectedEvent = undefined;
            },
        },
        methods: {
            selectEvent(event) {
                this.selectedEvent = event;
            },
        },
    };
</script>
