<template>
    <v-row>
        <v-col>
            <v-card outlined>
                <v-card-title>Event: {{ event.event  }}</v-card-title>
                <v-divider />
                <v-card-text class="text-left">
                    <v-row>
                        <v-col>
                            <b>Timestamp</b>
                            <div>{{ event.timestamp  }}</div>
                        </v-col>
                        <v-col>
                            <b>User</b>
                            <div>{{ event.user_id  }}</div>
                        </v-col>
                        <v-col>
                            <b>Source</b>
                            <div>{{ event.source  }}</div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-text class="text-left">
                    <v-row style="text-left">
                        <v-col cols=6 v-for="prop in properties.properties" :key="prop.key">
                            <b>{{ prop.key  }}</b>
                            <div>{{ prop.value }}</div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider v-if="properties.nested.length > 0" />
                <v-row>
                    <v-col>
                        <v-expansion-panels elevation=0 accordion class="text-left">
                            <v-expansion-panel
                                v-for="(item,i) in properties.nested"
                                :key="i"
                                style="text-left"
                            >
                                <v-expansion-panel-header>
                                    {{ item.key }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content style="text-left">
                                    <v-row style="text-left">
                                        <v-col cols=6 v-for="child in item.children" :key="child.key">
                                            <b>{{ child.key  }}</b>
                                            <div>{{ child.value }}</div>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import { flattenObject } from '@/utils';

    function flattenToArray(obj) {
        const flattened = flattenObject(obj);
        const arr = [];
        Object.keys(flattened).forEach((key) => {
            const value = flattened[key];
            arr.push({ key, value });
        });
        return arr;
    }

    export default {
        name: 'ProductAnalyticsViewerDetails',

        props: {
            event: Object,
        },

        computed: {
            properties() {
                const properties = [];
                const nested = [];

                Object.keys(this.event.properties).forEach((key) => {
                    const value = this.event.properties[key];
                    if (value.constructor === Object) {
                        const children = flattenToArray(value);
                        nested.push({ key, children });
                    } else {
                        properties.push({ key, value });
                    }
                });

                return { properties, nested };
            },
        },
    };
</script>
